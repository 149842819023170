<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="UN Friction Test – Test 3 (b) (i)"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="BAM Friction Setup"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/setupbamfriction-min.jpg')"
              target="_blank"
            >
              <img class="m-auto" src="@/assets/UN/setupbamfriction-min.jpg" />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to measure the sensitiveness of the substance to
              frictional stimuli and to determine if the substance is unstable
              in the form it was tested.
            </p>
            <TitleComponent
              class="pt-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              There are four different friction sensitivity tests in the UN
              Manual of Tests and criteria. The UN recommended test is the BAM
              Friction test.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent
              class="pt-8"
              title="BAM Friction Key Parameters"
              size="medium"
            />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Force applied to sample</td>
                    <td>Force applied to determine the sample’s response</td>
                    <td></td>
                    <td>
                      The test is completed at &lt;80 N (or 60 N) to determine
                      if the lowest friction load at which one “explosion”
                      occurs in 6 trials is less than 80 N.
                    </td>
                  </tr>
                  <tr>
                    <td>Anvil speed/movement</td>
                    <td>Provide a repeatable movement of the anvil</td>
                    <td></td>
                    <td>
                      The porcelain plate is held in a carriage which runs in
                      two guides. The carriage is connected to an electric motor
                      via a connecting rod, an eccentric cam and suitable
                      gearing such that the porcelain plate is moved, once only,
                      backwards and forwards beneath the porcelain peg a
                      distance of 10mm.
                    </td>
                  </tr>
                  <tr>
                    <td>Loading device</td>
                    <td>
                      Consistent loading of sample beneath the porcelain peg
                    </td>
                    <td></td>
                    <td>
                      The loading device pivots on an axis so that the porcelain
                      peg can be changed; it is extended by a loading arm which
                      is fitted with 6 notches for the attachment of a weight.
                      The use of weights in different notches results in loads
                      on the peg of 5-10-20-40-60-80-120-160-240-360 N.
                    </td>
                  </tr>
                  <tr>
                    <td>Porcelain plate and peg parameters</td>
                    <td>
                      Strongly influences the amount of pressure applied to the
                      sample
                    </td>
                    <td></td>
                    <td>
                      Porcelain pegs are rounded with 10 mm dia and 15 mm
                      height; plates are 25 x 25 x 5 mm
                    </td>
                  </tr>
                  <tr>
                    <td>Surface finish of peg and anvil</td>
                    <td>Provide a consistent friction insult per area</td>
                    <td></td>
                    <td>
                      The flat porcelain plates are made from technical white
                      porcelain and, before being fired, their two rubbing
                      surfaces (roughness 9 – 32 microns) are thoroughly
                      roughened by being rubbed with a sponge. The sponge marks
                      are clearly visible. The cylindrical porcelain pegs are
                      also in technical white porcelain and their roughened ends
                      are rounded.
                    </td>
                  </tr>
                  <tr>
                    <td>Sample thickness/amount</td>
                    <td>
                      Repeatably test a standardize thickness or amount of
                      material
                    </td>
                    <td></td>
                    <td>
                      Approximately 10 mm^3 of material is tested with
                      paste-like samples tested with thickness of 0.5 mm. The
                      peg should initially rest on the sample and there should
                      be enough sample that the substance comes under the peg
                      when the plate moves.
                    </td>
                  </tr>
                  <tr>
                    <td>Characterization of reaction types</td>
                    <td>
                      Provide consistent standards for judgment and evaluation
                      of a material
                    </td>
                    <td></td>
                    <td>
                      “Explosion” considered a reaction which is an audible
                      report, crackling, sparking, or flame
                    </td>
                  </tr>
                  <tr>
                    <td>Test Temperature and Humidity</td>
                    <td>
                      Determine the temperature/ humidity dependence of the
                      sample’s sensitivity as applicable. Energetics typically
                      become more sensitive with an increase in temperature.
                    </td>
                    <td></td>
                    <td>Standardized as ambient temperature and humidity</td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Adequately determine the relative sensitivity result with
                      the fewest number of trials
                    </td>
                    <td></td>
                    <td>At least 6 trials</td>
                  </tr>
                  <tr>
                    <td>Sample condition</td>
                    <td>Repeatably obtain results for a given sample</td>
                    <td></td>
                    <td>
                      Substances in powder form are passed through a 0.5 mm
                      sieve with the material passing through the sieve tested
                      with solid samples broken and passed through the same
                      sieve. Substances only transported in the form of disks or
                      chips are tested in that form with approximately 10 mm^3
                      of material (4 mm min dia.)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent
              class="pt-8"
              title="BAM Friction Indicators"
              size="medium"
            />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Reaction upon insult</td>
                    <td>
                      Detection of explosion (report, crackling, sparking or
                      flame)
                    </td>
                    <td>
                      The test result is considered “+” if the lowest friction
                      load at which one “explosion”
                      <span style="color: a800000;">[subjective]</span> occurs
                      in six trials is less than 80 N and the substance is
                      considered unstable in the form it was tested.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full p-10">
            <TitleComponent
              class="pt-8"
              title="No Reaction (-) Example Videos"
              size="medium"
            />
            <p class="p-light">
              Standard Video of the same event (360 N load) on the left (above),
              high-speed video (1000 frames per second) on the right (below)
            </p>
            <div class="flex flex-wrap mt-2">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028706?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028706"
                  allow="autoplay; fullscreen"
                  id="player_1"
                  name="fitvid0"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028699?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028699"
                  allow="autoplay; fullscreen"
                  id="player_2"
                  name="fitvid1"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="w-full p-10">
            <TitleComponent
              title="Explosion (+) Example Videos"
              size="medium"
            />
            <p class="p-light my-3">
              Standard Video of the same event (360 N load various materials) on
              the left (above), high-speed video (1000 frames per second) on the
              right (below). Videos provided by
              <a
                class="link"
                target="_blank"
                href="http://www.smsenergetics.com/"
                >Safety Management Services, Inc.</a
              >
            </p>
            <span class="font-bold">Reaction: Slight crackling</span>
            <div class="flex flex-wrap my-4">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028708?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028708"
                  allow="autoplay; fullscreen"
                  id="player_3"
                  name="fitvid2"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028700?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028700"
                  allow="autoplay; fullscreen"
                  id="player_4"
                  name="fitvid3"
                ></iframe>
              </div>
            </div>
            <hr style="border-color: rgb(239, 154, 61);" class="mt-12 mb-8" />
            <span class="font-bold">Reaction: Crackling</span>
            <div class="flex flex-wrap mt-4">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028701?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028701"
                  allow="autoplay; fullscreen"
                  id="player_5"
                  name="fitvid4"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028694?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028694"
                  allow="autoplay; fullscreen"
                  id="player_6"
                  name="fitvid5"
                ></iframe>
              </div>
            </div>
            <hr style="border-color: rgb(239, 154, 61);" class="mt-12 mb-8" />
            <span class="font-bold">Reaction: Snap</span>
            <div class="flex flex-wrap mt-4">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028707?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028707"
                  allow="autoplay; fullscreen"
                  id="player_7"
                  name="fitvid6"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028697?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028697"
                  allow="autoplay; fullscreen"
                  id="player_8"
                  name="fitvid7"
                ></iframe>
              </div>
            </div>
            <hr style="border-color: rgb(239, 154, 61);" class="mt-12 mb-8" />
            <span class="font-bold">Snapping Example A</span>
            <div class="flex flex-wrap mt-4">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028711?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028711"
                  allow="autoplay; fullscreen"
                  id="player_9"
                  name="fitvid8"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028702?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028702"
                  allow="autoplay; fullscreen"
                  id="player_10"
                  name="fitvid9"
                ></iframe>
              </div>
            </div>
            <hr style="border-color: rgb(239, 154, 61);" class="mt-12 mb-8" />
            <span class="font-bold">Snapping Example B</span>
            <div class="flex flex-wrap mt-4">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028710?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028710"
                  allow="autoplay; fullscreen"
                  id="player_11"
                  name="fitvid10"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028704?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028704"
                  allow="autoplay; fullscreen"
                  id="player_12"
                  name="fitvid11"
                ></iframe>
              </div>
            </div>
            <hr style="border-color: rgb(239, 154, 61);" class="mt-12 mb-8" />
            <span class="font-bold">Reaction: Report/ Spark Example A</span>
            <div class="flex flex-wrap mt-4">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028703?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028703"
                  allow="autoplay; fullscreen"
                  id="player_13"
                  name="fitvid12"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028696?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028696"
                  allow="autoplay; fullscreen"
                  id="player_14"
                  name="fitvid13"
                ></iframe>
              </div>
            </div>
            <hr style="border-color: rgb(239, 154, 61);" class="mt-12 mb-8" />
            <span class="font-bold">Reaction: Report/ Spark Example B</span>
            <div class="flex flex-wrap mt-4">
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028705?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028705"
                  allow="autoplay; fullscreen"
                  id="player_15"
                  name="fitvid14"
                ></iframe>
              </div>
              <div class="w-full md:w-1/2">
                <iframe
                  class="m-auto"
                  src="https://player.vimeo.com/video/144028698?autoplay=0&amp;autopause=0"
                  allowfullscreen=""
                  title="vimeo144028698"
                  allow="autoplay; fullscreen"
                  id="player_16"
                  name="fitvid15"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SideBar class="w-full sm:w-1/5" />
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
